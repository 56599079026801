import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import { Footer } from "./components/Footer";

const LINKS = [
  { name: "Start", link: "/", file: "Home" },
  { name: "Password Hashing", link: "/hasher", file: "Hasher" },
  { name: "Key Generator", link: "/keygen", file: "KeyGen" },
  { name: "Base64", link: "/base64", file: "Base64" },
  { name: "JWT", link: "/jwt", file: "JWT" },
  // { name: "Bcrypt", link: "/bcrypt", file: "Bcrypt" }
  // { name: "AES", link: "/aes", file: "AES" }
];

function App() {
  return (
    <BrowserRouter>
      <Header links={LINKS} />
      <div className="inner cover">
        {LINKS.map(l => {
          try {
            return (
              <Route
                key={l.link}
                exact
                path={l.link}
                component={require(`./screens/${l.file}`).default}
              />
            );
          } catch (err) {
            return null;
          }
        }).filter(l => l)}
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
