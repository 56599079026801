import React, { useEffect } from "react";

function Home() {
  useEffect(() => {
    document.title = `Useful tools for developers and everyone else - tools.alexbabel.com`;
  }, []);

  return (
    <div>
      <h2
        style={{
          textAlign: "left",
          borderLeft: "white 3px solid",
          paddingLeft: 20,
          marginBottom: 40,
        }}
      >
        Technology is nothing. What’s important is that you have a faith in
        people, that they’re basically good and smart, and if you give them
        tools, they’ll do wonderful things with them.
        <br />
        <a
          className="lead"
          href="https://www.rollingstone.com/culture/culture-news/steve-jobs-in-1994-the-rolling-stone-interview-231132/"
        >
          - Steve Jobs
        </a>
      </h2>
      <p className="lead">
        I created this site for small tasks I encounter on a almost daily basis
        and made it public in order to help other people who might have the same
        small but annoying tasks to do. Just take a look in the navigation bar
        and maybe I can help you with this site to make your developer life a
        bit easier.
        <br />
        <br />
        <strong>
          Everything runs local and no private data is send to any server.
        </strong>
      </p>
    </div>
  );
}

export default Home;
