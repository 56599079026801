import React, { useState } from "react";
import { Typography, Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import crypto from "crypto-js";
import { STATUS, getValidateStatus, getHelpText } from "../helper/validator";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  },
  style: {
    marginTop: 30
  }
};

function decrypt(encrypted, secret) {
  const obj = { decryptStatus: STATUS.PENDING, encrypted, secret };
  if (!encrypted || !secret) {
    return obj;
  }

  obj.decrypted = crypto.AES.decrypt(encrypted, secret).toString(
    crypto.enc.Utf8
  );
  return obj;
}

function encrypt(decrypted, secret) {
  const obj = { encryptStatus: STATUS.PENDING, decrypted, secret };
  if (!decrypted || !secret) {
    return obj;
  }

  obj.encrypted = crypto.AES.encrypt(decrypted, secret);
  return obj;
}

export default function AES(props) {
  const [value, setValue] = useState({
    decryptStatus: STATUS.PENDING,
    encryptStatus: STATUS.PENDING
  });

  function handleEncryptedChange(encrypted) {
    setValue({
      ...value,
      ...decrypt(encrypted, value.secret)
    });
  }

  function handleSecretChange(secret) {
    if (value.encrypted) {
      setValue({ ...value, secret, ...decrypt(value.encrypted, secret) });
      return;
    } else if (value.decrypted) {
      setValue({ ...value, secret, ...encrypt(value.decrypted, secret) });
      return;
    }

    setValue({ ...value, secret });
  }

  function handleDecryptedChange(decrypted) {
    setValue({
      ...value,
      ...encrypt(decrypted, value.secret)
    });
  }

  return (
    <div>
      <Typography.Title>AES (Advanced Encryption Standard)</Typography.Title>
      <Typography.Title level={4}>
        Everything is running local and will never leave your computer!
      </Typography.Title>
      <Form {...formItemLayout} onSubmit={e => e.preventDefault()}>
        <Form.Item
          label="Encrypted"
          hasFeedback
          validateStatus={getValidateStatus(value.decodeStatus)}
          help={getHelpText("encrypted", value.decodeStatus, "AES")}
        >
          <Input.TextArea
            value={value.encrypted}
            onChange={e => handleEncryptedChange(e.target.value)}
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="Please input your encrypted string here."
            autoComplete="off"
            autoCapitalize="off"
            style={{ resize: "vertical", minHeight: 100 }}
          />
        </Form.Item>
        <Form.Item
          label="Secret"
          hasFeedback
          validateStatus={getValidateStatus(value.verifyStatus)}
          help={getHelpText("secret", value.verifyStatus, "AES")}
        >
          <Input
            value={value.secret}
            onChange={e => handleSecretChange(e.target.value)}
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="Please input your secret."
            autoComplete="off"
            autoCapitalize="off"
          />
        </Form.Item>
        <Form.Item
          label="Decrypted"
          hasFeedback
          validateStatus={getValidateStatus(value.jsonStatus)}
          help={getHelpText("decrypted", value.jsonStatus, "AES")}
        >
          <Input.TextArea
            value={value.decrypted}
            onChange={e => handleDecryptedChange(e.target.value)}
            type="text"
            placeholder="Decrypted"
            autoComplete="off"
            autoCapitalize="off"
            style={{ resize: "vertical", minHeight: 100 }}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
