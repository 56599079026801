import React from "react";
import { Typography } from "antd";
import { withRouter } from "react-router-dom";
import "./Header.css";

function Header(props) {
  function isActive(path) {
    if (path === props.location.pathname) {
      return " active";
    }
    return "";
  }

  function getLink({ link, name }) {
    return (
      <a key={link} className={"nav-link" + isActive(link)} href={link}>
        {name}
      </a>
    );
  }

  return (
    <div className="masthead clearfix">
      <div className="inner">
        <Typography.Title level={2} className="masthead-brand">
          Tools
        </Typography.Title>
        <nav className="nav nav-masthead">{props.links.map(l => getLink(l))}</nav>
      </div>
    </div>
  );
}

export default withRouter(Header);
