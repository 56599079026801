import React, { useState, useEffect } from "react";
import { Typography, Form, Input } from "antd";
import { getValidateStatus, getHelpText, STATUS } from "../helper/validator";
import { LockOutlined } from "@ant-design/icons";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  },
  style: {
    marginTop: 30
  }
};

export default function Base64(props) {
  const [value, setValue] = useState({ encodedStatus: STATUS.PENDING });

  useEffect(() => {
    document.title = `Base64 - Tools`;
  }, []);

  function handleEncodedChange(encoded) {
    try {
      const decoded = atob(encoded);
      setValue({
        ...value,
        encoded,
        decoded,
        encodedStatus: STATUS.SUCCESS
      });
    } catch (err) {
      setValue({
        ...value,
        encoded,
        encodedStatus: STATUS.FAILURE
      });
    }
  }

  function handleDecodedChange(decoded) {
    setValue({
      ...value,
      encoded: btoa(decoded),
      decoded,
      encodedStatus: STATUS.PENDING
    });
  }

  return (
    <div>
      <Typography.Title>Base64</Typography.Title>
      <Form {...formItemLayout} onSubmit={e => e.preventDefault()}>
        <Form.Item
          label="Encoded"
          hasFeedback
          validateStatus={getValidateStatus(value.encodedStatus)}
          help={getHelpText("encoded", value.encodedStatus, 'Base64')}
        >
          <Input.TextArea
            value={value.encoded}
            onChange={e => handleEncodedChange(e.target.value)}
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="Please input your Base64 string here."
            autoComplete="off"
            autoCapitalize="off"
            style={{ resize: "vertical", minHeight: 100 }}
          />
        </Form.Item>
        <Form.Item label="Decoded">
          <Input.TextArea
            value={value.decoded}
            onChange={e => handleDecodedChange(e.target.value)}
            type="text"
            placeholder="Decoded String"
            autoComplete="off"
            autoCapitalize="off"
            style={{ resize: "vertical", minHeight: 100 }}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
