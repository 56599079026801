import React, { useState, useEffect } from "react";
import { Typography, Form, Input } from "antd";
import jwt from "jsonwebtoken";
import { STATUS, getValidateStatus, getHelpText } from "../helper/validator";
import { LockOutlined } from "@ant-design/icons";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  },
  style: {
    marginTop: 30
  }
};

function decodeJWT(token) {
  const obj = { token, decodeStatus: STATUS.PENDING };
  if (!token) {
    return obj;
  }

  const decoded = jwt.decode(token);
  if (!decoded) {
    obj.decodeStatus = STATUS.FAILURE;
    return obj;
  }

  obj.decoded = JSON.stringify(decoded, null, 2);
  obj.decodeStatus = STATUS.SUCCESS;
  obj.jsonStatus = STATUS.SUCCESS;
  return obj;
}

function verifyJWT(token, secret) {
  const obj = { token, secret, verifyStatus: STATUS.PENDING };
  if (!token || !secret) {
    return obj;
  }

  try {
    const decoded = jwt.verify(token, secret);
    obj.decoded = JSON.stringify(decoded, null, 2);
    obj.verifyStatus = STATUS.SUCCESS;
    obj.jsonStatus = STATUS.SUCCESS;
  } catch (err) {
    obj.verifyStatus = STATUS.FAILURE;
  }

  return obj;
}

export default function JWT(props) {
  const [value, setValue] = useState({
    decodeStatus: STATUS.PENDING,
    verifyStatus: STATUS.PENDING
  });

  useEffect(() => {
    document.title = `JWT - Tools`;
  }, []);

  function handleTokenChange(token) {
    setValue({
      ...value,
      ...decodeJWT(token),
      ...verifyJWT(token, value.secret)
    });
  }

  function handleSecretChange(secret) {
    if (value.decoded) {
      const token = jwt.sign(value.decoded, secret);
      setValue({ ...value, token, secret, verifyStatus: STATUS.SUCCESS });
      return;
    }

    setValue({
      ...value,
      ...decodeJWT(value.token),
      ...verifyJWT(value.token, secret)
    });
  }

  function handleDecodedChange(decoded) {
    try {
      const obj = JSON.parse(decoded);
      if (value.secret) {
        const token = jwt.sign(obj, value.secret);
        if (!obj.iat) {
          decoded = JSON.stringify(jwt.decode(token), null, 2);
        }

        setValue({
          ...value,
          token,
          decoded,
          decodeStatus: STATUS.SUCCESS,
          verifyStatus: STATUS.SUCCESS,
          jsonStatus: STATUS.SUCCESS
        });
        return;
      }

      setValue({ ...value, decoded });
    } catch (err) {
      setValue({
        ...value,
        decoded,
        jsonStatus: STATUS.FAILURE
      });
    }
  }

  return (
    <div>
      <Typography.Title>JWT (JSON Web Tokens)</Typography.Title>
      <Typography.Title level={4}>
        Everything is running local and will never leave your computer!
      </Typography.Title>
      <Form {...formItemLayout} onSubmit={e => e.preventDefault()}>
        <Form.Item
          label="Token"
          hasFeedback
          validateStatus={getValidateStatus(value.decodeStatus)}
          help={getHelpText("token", value.decodeStatus, 'JWT')}
        >
          <Input.TextArea
            value={value.token}
            onChange={e => handleTokenChange(e.target.value)}
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="Please input your token here."
            autoComplete="off"
            autoCapitalize="off"
            style={{ resize: "vertical", minHeight: 100 }}
          />
        </Form.Item>
        <Form.Item
          label="Secret"
          hasFeedback
          validateStatus={getValidateStatus(value.verifyStatus)}
          help={getHelpText("secret", value.verifyStatus, 'JWT')}
        >
          <Input
            value={value.secret}
            onChange={e => handleSecretChange(e.target.value)}
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="Please input your secret."
            autoComplete="off"
            autoCapitalize="off"
          />
        </Form.Item>
        <Form.Item
          label="Decoded"
          hasFeedback
          validateStatus={getValidateStatus(value.jsonStatus)}
          help={getHelpText("decoded", value.jsonStatus, 'JWT')}
        >
          <Input.TextArea
            value={value.decoded}
            onChange={e => handleDecodedChange(e.target.value)}
            type="text"
            placeholder="Decoded Payload"
            autoComplete="off"
            autoCapitalize="off"
            style={{ resize: "vertical", minHeight: 100 }}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
