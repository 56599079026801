import React from "react";
import { Typography } from "antd";

export default function Bcrypt(props) {
  return (
    <div>
      <Typography.Title>Bcrypt</Typography.Title>
      Coming soon
    </div>
  );
}
