export const STATUS = {
  FAILURE: -1,
  PENDING: 0,
  SUCCESS: 1
};

const ERROR_TEXTS = {
  JWT: {
    token: "Your Token is not valid!",
    decoded: "Your JSON data is not valid!",
    secret: "Your secret is not valid!",
  },
  Base64: {
    encoded: "Your Base64 string is not valid!",
  },
  AES: {

  }
};

export function getValidateStatus(status) {
  switch (status) {
    case STATUS.FAILURE:
      return "error";
    case STATUS.SUCCESS:
      return "success";
    case STATUS.PENDING:
    default:
      return null;
  }
}

export function getHelpText(type, status, screen) {
  if (status !== STATUS.FAILURE) {
    return null;
  }

  return ERROR_TEXTS[screen][type];
}