import React from "react";
import './Footer.css';

export function Footer() {
  return (
    <div className="mastfoot">
      <div className="inner">
        <p>
          <span>&copy; {new Date().getFullYear()} Alexander Babel</span> |{" "}
          <a href="https://alexbabel.com">alexbabel.com</a> |{" "}
          <a href="https://alexbabel.com/privacy-policy">Privacy Policy</a> |{" "}
          <a href="https://alexbabel.com/imprint">Imprint</a>
        </p>
      </div>
    </div>
  );
}
