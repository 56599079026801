import React, { useEffect } from "react";
import { Input, Row, Col, Typography } from "antd";
import { getRandomKey } from "../helper/random";

const TYPES = [
  {
    name: "Short Passwords",
    amount: 6,
    length: 10,
    chars: ["numbers", "uppercase", "lowercase"],
    columns: 3,
  },
  {
    name: "Strong Passwords",
    amount: 6,
    length: 15,
    chars: ["numbers", "uppercase", "lowercase", "special"],
    columns: 3,
  },
  {
    name: "Extra Strong Passwords",
    amount: 4,
    length: 30,
    chars: ["numbers", "uppercase", "lowercase", "special"],
    columns: 2,
  },
  {
    name: "264-bit Passwords",
    amount: 4,
    length: 32,
    chars: ["numbers", "uppercase", "lowercase"],
    columns: 2,
  },
  {
    name: "160-bit WPA Key",
    amount: 4,
    length: "QJ,eH(4=_^BXZ%:hJ;#u".length,
    chars: ["numbers", "uppercase", "lowercase", "special"],
    columns: 2,
  },
  {
    name: "504-bit WPA Key",
    amount: 4,
    length: `='30ELG+S"3T(wC>fCMl6435=C];;'AOL@?]Vh]/0ixO1K{jcd}yWG%~\`W1Vk]Y`
      .length,
    chars: ["numbers", "uppercase", "lowercase", "special"],
  },
  {
    name: "64-bit WEP Keys",
    amount: 12,
    length: 5,
    chars: ["numbers", "uppercase"],
    columns: 4,
  },
  {
    name: "128-bit WEP Keys",
    amount: 12,
    length: 13,
    chars: ["numbers", "uppercase"],
    columns: 4,
  },
  {
    name: "152-bit WEP Keys",
    amount: 12,
    length: "2279A584AF34C732".length,
    chars: ["numbers", "uppercase"],
    columns: 4,
  },
  {
    name: "256-bit WEP Keys",
    amount: 4,
    length: "172EBE3112466D7E1E4DF217EF8BE".length,
    chars: ["numbers", "uppercase"],
    columns: 2,
  },
];

function getArray(length) {
  return Array.apply(null, { length }).map(Number.call, Number);
}

export default function KeyGen() {
  useEffect(() => {
    document.title = `Key Generator - Tools`;
  }, []);

  return (
    <div>
      <Typography.Title>Key Generator</Typography.Title>
      {TYPES.map((t) => (
        <>
          <Typography.Title style={{ marginTop: 20 }} level={4}>
            {t.name}
          </Typography.Title>
          <Row key={t.name}>
            {getArray(t.amount).map((e) => {
              const columns = t.columns || 1;
              const md = 24 / columns;
              const sm = 24 / (columns > 1 ? columns - 1 : 1);
              const xs = 24 / (columns > 2 ? columns - 2 : 1);
              return (
                <Col
                  key={t.name + e}
                  sm={sm}
                  xs={xs}
                  md={md}
                  style={{ padding: 5 }}
                >
                  <Input
                    style={{ textAlign: "center" }}
                    onClick={(el) => el.target.select()}
                    onChange={(e) => e.preventDefault()}
                    type="text"
                    value={getRandomKey(t.chars, t.length)}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      ))}
    </div>
  );
}
