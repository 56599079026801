export function getRandomString(length = 16) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function getChars(type) {
  switch (type) {
    case "number":
    case "numbers":
      return "0123456789";
    case "lowercase":
      return "abcdefghijklmnopqrstuvwxyz";
    case "uppercase":
      return "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    case 'special':
      return `!@#$%^&*()_+-={}[]"|'|?/><.,~§\`\\`;
    default:
      return "";
  }
}

export function getRandomKey(chars = ['lowercase', 'uppercase', 'numbers'], length = 16) {
  let result = "";
  const characters = chars.reduce((prev, cur) => prev + getChars(cur), "");
  const charactersLength = characters.length;
  for (var i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
