import React, { useState, useEffect } from "react";
import crypto from "crypto-js";
import { Typography, Form, Input, Button, Select, Radio } from "antd";
import { getRandomString } from "../helper/random";
import { ToolOutlined, LockOutlined } from "@ant-design/icons";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const SALT_TYPE = {
  GENERATED: 1,
  OWN_SALT: 2,
  NO_SALT: 3,
};

const ALGOS = [
  "SHA1",
  "SHA3",
  "SHA256",
  "SHA384",
  "SHA512",
  "MD5",
  "RIPEMD160",
];

export default function Hasher(props) {
  const [saltVisible, setSaltVisible] = useState(false);
  const [salt, setSalt] = useState();
  const [result, setResult] = useState();

  useEffect(() => {
    document.title = `Password Hasher - Tools`;
  }, []);

  function handleSubmit(values) {
    if (!ALGOS.includes(values.algorithm)) {
      return;
    }

    const obj = {};
    if (
      SALT_TYPE.GENERATED === values.salt ||
      SALT_TYPE.OWN_SALT === values.salt
    ) {
      obj.salt = values.ownSalt ? values.ownSalt : getRandomString();
      obj.hash = crypto[values.algorithm](values.password + obj.salt);

      if (values.ownSalt) {
        delete obj.salt;
      }
    } else {
      obj.hash = crypto[values.algorithm](values.password);
    }

    const el = document.querySelector(".resultSalt");
    if (el && !obj.salt) {
      el.classList.add(
        "animate__faster",
        "animate__fadeOut",
        "animate__animated"
      );
      el.addEventListener("animationend", () => setResult(obj));
      return;
    }

    setResult(obj);
  }

  return (
    <div className="left">
      <Typography.Title>Password Hasher</Typography.Title>
      <Typography.Title level={4}>
        Hash a password with or without a salt.
      </Typography.Title>
      <Form {...formItemLayout} onFinish={handleSubmit}>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input a Password!" }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="Password"
            autoComplete="off"
            autoCapitalize="off"
          />
        </Form.Item>
        <Form.Item
          label="Algorithm"
          name="algorithm"
          rules={[{ required: true, message: "Please select an algorithm!" }]}
          initialValue={ALGOS[4]}
        >
          <Select>
            {ALGOS.map((a) => (
              <Option key={a} value={a} className="left">
                {a}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Salt"
          name="salt"
          rules={[
            { required: true, message: "Please choose your salt variant!" },
          ]}
          initialValue={1}
        >
          <Radio.Group
            onChange={(e) => {
              const newVisibility = e.target.value === 2;
              if (newVisibility !== saltVisible) {
                if (!newVisibility) {
                  const el = document.querySelector(".ownSalt");
                  console.log(el);
                  if (el) {
                    el.classList.add(
                      "animate__faster",
                      "animate__fadeOut",
                      "animate__animated"
                    );
                    el.addEventListener("animationend", () =>
                      setSaltVisible(newVisibility)
                    );
                    return;
                  }
                }
                setSaltVisible(newVisibility);
              }
            }}
          >
            <Radio style={radioStyle} value={1}>
              Use random string as salt (recommended)
            </Radio>
            <Radio style={radioStyle} value={2}>
              choose own salt
            </Radio>
            <Radio style={radioStyle} value={3}>
              Do not use a salt (not recommended)
            </Radio>
          </Radio.Group>
        </Form.Item>
        {saltVisible && (
          <Form.Item
            label="Your Salt"
            className="animate__fadeIn animate__animated ownSalt"
            name="ownSalt"
            rules={[{ required: true, message: "Please enter your own salt!" }]}
            initialValue={salt}
          >
            <Input
              onChange={(e) => setSalt(e.target.value)}
              prefix={<ToolOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="text"
              placeholder="Your Salt"
              autoComplete="off"
              autoCapitalize="off"
            />
          </Form.Item>
        )}
        {!saltVisible && <div className="empty-input" />}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Hash It!
          </Button>
        </Form.Item>
        {result && (
          <div className="animate__fadeIn animate__animated">
            <Form.Item label="Hash">
              <Input
                onChange={(e) => e.preventDefault()}
                onClick={(e) => e.target.select()}
                type="text"
                value={result.hash}
              />
            </Form.Item>
            {!result.salt && <div className="empty-input" />}
            {!!result.salt && (
              <div className="animate__fadeIn animate__animated resultSalt">
                <Form.Item label="Salt">
                  <Input
                    onChange={(e) => e.preventDefault()}
                    onClick={(e) => e.target.select()}
                    type="text"
                    value={result.salt}
                  />
                </Form.Item>
              </div>
            )}
          </div>
        )}
        {!result && (
          <div>
            <div className="empty-input" />
            <div className="empty-input" />
          </div>
        )}
      </Form>
    </div>
  );
}
